// Import libraries
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    & .overlay {
        display: grid;
        grid-template-rows: 1fr 6rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
        align-items: center;
        justify-content: center;

        & .content {
            max-width: 1100px;
            margin-top: 100px; /* Equal to header height */
            padding: 0 var(--padding-right) 0 var(--padding-left);
            color: #fff;

            & .primary-color {
                color: var(--color-primary);
            }

            & h1 {
                font-size: var(--font-size-banner-h1);
                font-weight: var(--font-weight-banner-h1);
                line-height: var(--line-height-banner-h1);
            }

            & h2 {
                font-size: var(--font-size-banner-h2);
                font-weight: var(--font-weight-banner-h2);
                line-height: var(--line-height-banner-h2);
                color: var(--color-primary);
            }

            &-link {
                justify-self: start;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;

                & span {
                    margin-right: 10px;
                }
            }

            &-link:link,
            &-link:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
            }

            &-link:hover,
            &-link:focus {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            &-link:active {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            margin-left: var(--padding-left);
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledContactContainer = styled.div`
    display: grid;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    height: auto;
    min-width: var(--body-min-width);
    background-color: var(--color-background-1);
    color: #fff;

    & .scroll-to-top-container {
        display: block;
        width: 100%;
        max-width: 1100px;
        height: 6rem;
        margin: 0 auto;
        align-self: start;

        & .scroll-to-top-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem; /* Equal to StyledContactContainer .content margin-top (-) */
            margin-left: var(--padding-left);
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }

    & .content {
        position: relative;
        display: grid;
        grid-template-rows: repeat(4, max-content);
        grid-row-gap: 5rem;
        justify-items: center;
        padding: 0 var(--padding-right) 6rem var(--padding-left);

        &-container {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            justify-items: start;
        }

        &-handwritten {
            font-family: 'Kalam', var(--font-family);
            font-size: 2.5rem;
            font-weight: 400;
            color: var(--color-primary);
        }
        
        &-link:link,
        &-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
            font-size: 10rem;
            font-weight: 600;
            cursor: pointer;

            @media only screen and (max-width: 1300px) {
                font-size: 7.8rem;
            }

            @media only screen and (max-width: 800px) {
                font-size: 5.5rem;
            }

            @media only screen and (max-width: 550px) {
                font-size: 3.9rem;
            }
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
        }

        &-link:active {
            text-decoration: none;
            color: #fff;
        }

        &-link--small:link,
        &-link--small:visited {
            font-weight: var(--font-weight-body-p);
            font-size: var(--font-size-body-p);
        }
    }
`;

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 400px;
    margin: 5px auto 0;
    grid-row-gap: 1rem;

    & input::placeholder,
    & textarea::placeholder {
        color: #777676;
    }

    & input,
    & textarea {
        font-family: inherit;
        font-size: inherit;
        border-radius: 0;
        border: 1px solid #ccc;
        padding: 1rem 2rem;
        outline: none;
        width: 100%;
    }

    & textarea {
        resize: none;
    }

    & .button {
        display: flex;
        flex-direction: row;
        background-color: var(--color-primary);
        border: 2px solid var(--color-primary);
        border-radius: 0;
        font-size: 2rem;
        padding: 1rem 2rem;
        color: #fff;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & span {
            margin-right: 10px;
        }
    }

    & .button:hover,
    & .button:focus {
        outline: 0;
        transform: translateY(-3px);
        box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
    }

    & .button:active {
        outline: 0;
        transform: translateY(0);
        box-shadow: none;
    }
`;


// Create component
const ContactPage = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    phoneNumber
                    whatsappNumber
                    email
                }
            }

            bannerImage: file(relativePath: { eq: "banner/contact/banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    
    // Handle scrolling down to below banner
    const handleScrollDown = () => {
        // Get banner height
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        
        // Scroll to place underneath banner
        window.scrollTo({
            top: bannerHeight,
            left: 0,
            behavior: 'smooth'
        });
    };


    // Handle scrolling to top of page
    const handleScrollToTop = () => {
        // Scroll to top of page
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Contact" />
            <Layout
                withBanner={true}
                removeFooter={true}
            >
                <StyledBanner
                    className="banner"
                    image={data.bannerImage}
                >
                    <div className="overlay">
                        <div className="content">
                            <h1>Every company is <span className="primary-color">unique</span>. Therefore we are meeting with our clients either in <span className="primary-color">person or virtually</span> to talk about the services you are interested in. This leads to <span className="primary-color">perfectly custom made products</span> for your company.</h1>
                        </div>

                        <div className="scroll-down-button" onClick={handleScrollDown}>
                            <FontAwesomeIcon icon={faArrowDown} className="icon" />
                        </div>
                    </div>
                </StyledBanner>

                <StyledContactContainer>
                    <div className="scroll-to-top-container">
                        <div className="scroll-to-top-button" onClick={handleScrollToTop}>
                            <FontAwesomeIcon icon={faArrowUp} className="icon" />
                        </div>
                    </div>

                    <div className="content">
                        <div className="content-container">
                            <p className="content-handwritten">Call us on</p>
                            <a href={`tel:${data.site.siteMetadata.phoneNumber}`} target="_blank" rel="noopener noreferrer" className="content-link">{data.site.siteMetadata.phoneNumber}</a>
                        </div>

                        <div className="content-container">
                            <p className="content-handwritten">Send us a Whatsapp on</p>
                            <a href={`whatsapp:${data.site.siteMetadata.whatsappNumber}`} target="_blank" rel="noopener noreferrer" className="content-link">{data.site.siteMetadata.whatsappNumber}</a>
                        </div>

                        <div className="content-container">
                            <p className="content-handwritten">Or use the contact form below</p>
                            
                            <StyledForm name="contact-page-contact-form" action="/sent?type=contact-page-contact-form" method="POST" enctype="application/x-www-form-urlencoded" data-netlify="true" netlify>
                                <input type="hidden" name="form-name" value="contact-page-contact-form" />
                                <input type="text" name="name" placeholder="Your name" required />
                                <input type="text" name="phone-number" placeholder="Your number" required />
                                <input type="text" name="email" placeholder="Your email" required />
                                <textarea name="message" placeholder="Your message" rows="6" required></textarea>
                                <button type="submit" className="button">
                                    <span>Send Message</span>
                                    <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                                </button>
                            </StyledForm>
                        </div>
                    </div>
                </StyledContactContainer>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default ContactPage;